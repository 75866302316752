import React from "react"
import ScrollAnimation from "react-animate-on-scroll"
import { Link } from "gatsby"
// import MySwiper from "./swiper/swiper"
// import Swiper from "react-id-swiper"
// import { Button, Modal, ModalBody, ModalHeader } from "reactstrap"
import "swiper/css/swiper.css"
// import { Navigation, Pagination } from "swiper/js/swiper.esm"
import { reviewData } from "../../components/reviewData"
import "./testi-new.scss"
// import { FaUserCircle } from "react-icons/fa"
import { AiFillLinkedin } from "react-icons/ai"
import UserPlaceHolder from "../../images/new-images/testimonial-images/user.png"

// const ContactModal = props => {
//   const { buttonLabel, item } = props
//   const [modal, setModal] = useState(false)
//   const toggle = () => setModal(!modal)
//   // console.log(item)

//   return (
//     <>
//       <Button
//         aria-label="btn"
//         style={{
//           background: "none",
//           border: "none",
//           padding: 0,
//           textTransform: "capitalize",
//         }}
//         onClick={toggle}
//       >
//         <strong>{buttonLabel}</strong>
//       </Button>

//       <Modal
//         centered={true}
//         isOpen={modal}
//         toggle={toggle}
//         className="contactNewTesti"
//       >
//         <ModalHeader toggle={toggle}>
//           <h4 className="mb-0">{item.title}</h4>
//         </ModalHeader>
//         <ModalBody>
//           {item.review}
//           <br />
//           <br />
//           <h5 className="title">{item.reviewer}</h5>
//           <h5 className="title">{item.position}</h5>
//         </ModalBody>
//       </Modal>
//     </>
//   )
// }

function Testimonial() {
  return (
    <section className="intro bg-gradient" style={{ padding: "5rem 0" }} id="">
      <div className="container mb-5">
        <div className="row">
          <div className="col-lg-12">
            <p className="h5 text-light-blue text-center text-uppercase font-weight-bold m-0">
              Reviews
            </p>
            <h2 className="my-2 text-white mt-4 text-center">
              What Our Clients Say
            </h2>
          </div>
        </div>
      </div>
      <div className="new-testimonial">
        <ScrollAnimation
          animateIn="fadeIn"
          initiallyVisible={false}
          animateOnce={true}
        >
          <div className="container masonry-with-columns">
            {/* <!--BEGIN: Masonry Grid--> */}
            {reviewData.map((item, idx) => {
              // const limitReview = item.review.substring(0, 160)
              return (
                <div key={idx} className="grid-item my-4">
                  <div className="testi-item">
                    <div className="testi-item-inner">
                      <div className="testimonial-content newContent">
                        <p className="text-left">
                          {item.review}
                          {/* {limitReview.length >= 160 ? (
                            <>
                              <br />
                              <span>
                                ...
                                <ContactModal
                                  item={item}
                                  buttonLabel="Read More"
                                />
                              </span>
                            </>
                          ) : (
                            ""
                          )} */}
                        </p>
                      </div>
                      <div className="testimonial-holder mt-4">
                        <div className="testimonial-meta row">
                          <div className="col-md-2 col-sm-3 col-2 pr-0">
                            {item.headShot ? (
                              <img
                                src={item?.headShot}
                                className="img-fluid rounded-circle "
                                alt={item?.reviewer}
                                style={{ width: "70px" }}
                              />
                            ) : (
                              <img
                                src={UserPlaceHolder}
                                className="img-fluid rounded-circle "
                                alt="placeholder image"
                                style={{ width: "70px" }}
                              />
                            )}
                          </div>
                          <div className="col-md-10 col-sm-9 col-10 text-left">
                            <p className="h5 title my-0">{item?.reviewer}</p>
                            <p
                              className="company mb-0"
                              style={{ color: "#8a49a8", fontSize: "13px" }}
                            >
                              {item?.company}
                            </p>
                            {item?.linkedLink && (
                              <Link
                                to={item?.linkedLink}
                                aria-label={`Follow ${item?.reviewer} on LinkedIn`}
                                target="_blank"
                              >
                                <AiFillLinkedin size={"30px"} />
                              </Link>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )
            })}
            {/* <!--END: Masonry Grid--> */}
          </div>
        </ScrollAnimation>
      </div>
      <div className="text-center mt-2">
        <a
          className="text-light btn btn-arrow btn-primary mt-3 text-center mx-auto"
          href="https://www.Quickbase.com/partners/quandary-consulting-group"
          target="_blank"
          rel="noopener noreferrer"
          aria-label="see more quandary reviews"
        >
          See More Quandary Reviews
        </a>
      </div>
    </section>
  )
}

export default Testimonial
