import { Link } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import { getImage } from "gatsby-plugin-image"
import { convertToBgImage } from "gbimage-bridge"
import React from "react"
import loadable from "@loadable/component"

class Cases extends React.Component {
  render() {
    const cases = this.props.allSanityCaseStudy.edges

    return (
      <>
        <div id="item-featured-container" className="mt-2">
          <div className="container">
            <div className="row">
              {!cases
                ? null
                : cases.map((studies, idx) => {
                    // const StudyBannerImg = getImage(
                    //   studies.node.bannerImage
                    //     ? studies.node.bannerImage.asset.gatsbyImageData
                    //     : ""
                    // )
                    // const StudyBannerBgImg = convertToBgImage(StudyBannerImg)

                    const StudyMainImg = getImage(
                      studies.node.mainImage
                        ? studies.node.mainImage.asset.gatsbyImageData
                        : ""
                    )
                    const StudyMainBgImg = convertToBgImage(StudyMainImg)

                    return (
                      <div key={idx} className="col-md-4 mb-4 mb-md-0">
                        <div
                          // Tag="div"
                          id="hero-case-studies"
                          className="item-featured"
                          // Spread bgImage into BackgroundImage:
                          // {...StudyMainBgImg}
                          // preserveStackingContext
                          style={{
                            backgroundSize: "cover",
                            backgroundRepeat: "no-repeat",
                          }}
                        >
                          <Link
                            to={`/case-study/${studies.node.slug.current}/`}
                          >
                            <div className="item-lead">
                              <div className="nth-color">
                                <div className="item-overlay-color" />
                              </div>
                              <BackgroundImage
                                Tag="div"
                                id="hero-case-studies"
                                className="item-featured"
                                // Spread bgImage into BackgroundImage:
                                {...StudyMainBgImg}
                                preserveStackingContext
                                style={{
                                  borderRadius: "5px",
                                  backgroundSize: "cover",
                                  backgroundRepeat: "no-repeat",
                                  overflow: "hidden",
                                }}
                              />
                            </div>
                            <div className="item-content">
                              <p
                                className="h4 text-white mt-0 mb-2"
                                style={{ zIndex: 5 }}
                              >
                                {studies.node.title}
                              </p>
                            </div>
                          </Link>
                        </div>
                      </div>
                    )
                  })}
            </div>
          </div>
        </div>
      </>
    )
  }
}

export default Cases
